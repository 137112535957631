import { Controller } from '@hotwired/stimulus';
import 'tom-select/dist/css/tom-select.bootstrap5.css';
import controller_0 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';
import controller_1 from '@symfony/ux-turbo/dist/turbo_controller.js';

export default {
'symfony--ux-autocomplete--autocomplete': class extends Controller {
      constructor(context) {
        super(context);
        this.__stimulusLazyController = true;
      }
      initialize() {
        if (this.application.controllers.find((controller) => {
            return controller.identifier === this.identifier && controller.__stimulusLazyController;
        })) {
            return;
        }
        import('@symfony/ux-autocomplete/dist/controller.js').then((controller) => {
            this.application.register(this.identifier, controller.default);
        });
      }
    },
'live': controller_0,
'symfony--ux-turbo--turbo-core': controller_1
};
